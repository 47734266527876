import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Import axios for API calls
import ViewInventory from "../ViewInventory/Viewinventory";
import "../MovingLocation/MovingLocation.css";
import cardimage from "../../Assests/card_image.png";
import cardimage1 from "../../Assests/card_image1.png";
import cardimage2 from "../../Assests/card_image2.png";
import Size from "../SizeEstimate/Size";
import Searchitem from "../Searchitem/Searchitem";

// Utility function to post data to the API
const postEstimateType = async (estimateType) => {
  try {
    const enquiryId = localStorage.getItem("enquiry_id");
    const payload = {
      enquiry_id: enquiryId,
      eastimate_type: estimateType,
    };
    await axios.post(
      "https://booking.skilledmovers.com.au/api/estimate-type",
      payload
    );
  } catch (error) {
    console.error("Error posting estimate type:", error);
  }
};

function MovingLocation() {
  const [visibleSection, setVisibleSection] = useState(null);
  const [estimateMethod, setEstimateMethod] = useState(null);
  const [roomDetails, setRoomDetails] = useState({
    bedrooms: 2,
    bathrooms: 1,
    kitchen: 1,
    office: 0,
    livingRoom: 1,
    diningRoom: 1,
    laundry: 1,
    garage: 1,
    carport: 0,
    outdoorArea: 1,
    hallway: 1,
    micro: 0,
  });
  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top
  }, []);

  useEffect(() => {
    if (estimateMethod === "roomByRoom") {
      // Enable button if any room detail is greater than 0
      const isRoomSelected = Object.values(roomDetails).some(
        (count) => count > 0
      );
      setButtonDisabled(!isRoomSelected);
    } else if (
      estimateMethod === "quickEstimate" ||
      estimateMethod === "searchItems"
    ) {
      // Enable button if quickEstimate or searchItems is selected
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [estimateMethod, roomDetails]);

  const navigate = useNavigate();

  const handleIncrement = (room) => {
    setRoomDetails((prevDetails) => ({
      ...prevDetails,
      [room]: prevDetails[room] + 1,
    }));
  };

  const handleDecrement = (room) => {
    setRoomDetails((prevDetails) => ({
      ...prevDetails,
      [room]: prevDetails[room] > 0 ? prevDetails[room] - 1 : 0,
    }));
  };

  const handlenextClick = () => {
    navigate("/movefrom");
  };

  const handleReset = () => {
    setVisibleSection(null);
    setEstimateMethod(null);
  };

  const handleViewInventory = () => {
    navigate("/roominventory");
  };

  const handleClick = (section) => {
    if (section === "inventory") {
      postEstimateType("1");
      const DetailsEstimete = "1";
      localStorage.setItem("DetailsEstimete", DetailsEstimete);
      navigate("/roominventory");
    }
    setVisibleSection(section);
  };

  const handlesizeClick = () => {
    postEstimateType("2");
    const QuickEstimete = "2";
    localStorage.setItem("QuickEstimete", QuickEstimete);
    navigate("/size");
  };

  const handlesearchitemClick = (route) => {
    postEstimateType("3");
    const HandleEstimete = "3";
    localStorage.setItem("HandleEstimete", HandleEstimete);
    navigate(route);
  };

  return (
    <div>
      <div className="access-properties">
        <div className="moving-location-container ">
          {visibleSection === null && (
            <>
              <div
                className="nav d-flex justify-content-around"
                role="tablist"
                aria-orientation="vertical"
              >
                <a className="nav-link ">Location</a>
                <a className="nav-link active">Move Size</a>
                <a className="nav-link">Date & Time</a>
                <a className="nav-link">Details</a>
                <a className="nav-link">My Quote</a>
              </div>
              <div className="mobile-navigation">
                {/* <button onClick={prevTab} disabled={activeTab === "tab1"}>
                &lt;
              </button> */}
                <span className="tab-label ">Move Size</span>
                {/* <button onClick={nextTab} disabled={activeTab === "tab5"}>
                &gt;
              </button> */}
              </div>
              <h2 className="heading__text__skilled mt-3 ">
                How much progress are we making?
              </h2>
              <div className="card-container">
                {/* Card 1 */}
                <div
                  className="card-link"
                  onClick={() => handleClick("inventory")}
                >
                  <div className="card">
                    <div className="row ">
                      <div className="col-md-4">
                        <img
                          src={cardimage}
                          alt="Image 1"
                          className="img-fluid"
                        />
                      </div>

                      <div className="col-md-8">
                        <div className="card-body">
                          <h4 className="card-title heading__text__skilled">
                            Create a thorough inventory.
                          </h4>
                          <p className="card-text ">
                            An inventory of each space individually for the most
                            precise quote.
                          </p>
                          <button className="btn inventory__use ">
                            Compile a list of items. →
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Card 2 */}
                <div className="card-link" onClick={handlesizeClick}>
                  <div className="card">
                    <div className="row ">
                      <div className="col-md-4">
                        <img
                          src={cardimage1}
                          alt="Image 2"
                          className="img-fluid"
                        />
                      </div>

                      <div className="col-md-8">
                        <div className="card-body">
                          <div className="most-popular">THE MOST COMMON</div>
                          <h4 className="card-title heading__text__skilled">
                            Obtain a speedy approximation of size.
                          </h4>
                          <p className="card-text">
                            Quickly calculate your volume by counting the rooms
                            and the furniture.
                          </p>
                          <button className="btn popular-btn button__text__skilled">
                            Make use of a quick estimate →
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Card 3 */}
                <div
                  className="card-link"
                  onClick={() => handlesearchitemClick("/searchitem")}
                >
                  <div className="card">
                    <div className="row ">
                      <div className="col-md-4">
                        <img
                          src={cardimage2}
                          alt="Image 3"
                          className="img-fluid"
                        />
                      </div>

                      <div className="col-md-8">
                        <div className="card-body">
                          <h4 className="card-title heading__text__skilled">
                            Just a few things
                          </h4>
                          <p className="card-text">
                            Merely a few sizable bits? A solitary item? Our goal
                            is to assist.
                          </p>
                          <button className="btn inventory__use">
                            Enumerate your belongings. →
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* {visibleSection === "inventory" && (
            <div id="inventory" className="target-section">
              <div
                className="nav d-flex justify-content-around"
                role="tablist"
                aria-orientation="vertical"
              >
                <a className="nav-link ">Location</a>
                <a className="nav-link active">Move Size</a>
                <a className="nav-link">Date & Time</a>
                <a className="nav-link">Details</a>
                <a className="nav-link">My Quote</a>
              </div>
              <h3>Create an inventory to assess the size of your move.</h3>
              <p>
                <a
                  href="#"
                  onClick={handleReset}
                  style={{ textDecoration: "none", color:"#ff5c00" }}
                >
                  Change the method for estimation.
                </a>
              </p>
              <div className="d-flex align-items-center justify-content-center ">
                <div
                  className="bg-white p-3 p-md-5 w-100"
                  style={{
                    borderRadius: "10px",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                >
                  <h4>How extensive is our move?</h4>
                  <div
                    className="content_inventory contact__details"
                    style={{ margin: "20px" }}
                  >
                    <label className="radio-label">
                      <input
                        type="radio"
                        name="estimateMethod"
                        value="roomByRoom"
                        onChange={() => setEstimateMethod("roomByRoom")}
                      />{" "}
                      Most of the goods in my property
                    </label>
                    <label className="radio-label">
                      <input
                        type="radio"
                        name="estimateMethod"
                        value="quickEstimate"
                        onChange={() => setEstimateMethod("quickEstimate")}
                      />{" "}
                      Just a few Items
                    </label>
                  </div>

                  {estimateMethod === "roomByRoom" ? (
                    <div className="inventory-form">
                      <h5 className="pt-2">
                        Could you provide more details about you location?
                      </h5>
                      <p>What rooms or areas are included in this property?</p>
                      <div className="room-details">
                        {Object.entries(roomDetails).map(([room, value]) => (
                          <div key={room} className="room-detail-row">
                            <label className="room-label">
                              {room.charAt(0).toUpperCase() + room.slice(1)}
                            </label>
                            <button
                              type="button"
                              className="room-button"
                              onClick={() => handleDecrement(room)}
                            >
                              -
                            </button>
                            <input
                              type="number"
                              className="room-input"
                              value={value}
                              readOnly
                            />
                            <button
                              type="button"
                              className="room-button"
                              onClick={() => handleIncrement(room)}
                            >
                              +
                            </button>
                          </div>
                        ))}
                      </div>

                      <div className="furnishings pt-3">
                        <div className="checkbox-row">
                          <input type="checkbox" defaultChecked />
                          <label style={{ textAlign: "justify" }}>
                            Automatically include common items in these rooms?
                          </label>
                        </div>
                        <p style={{ textAlign: "justify" }}>
                          This gives you a head start on your inventory. You can
                          remove any items that don’t apply to you.
                        </p>
                        <h4>
                          Describe the amount of furnishings in your home.
                        </h4>
                        <div className="content_inventory">
                          <label className="radio-label">
                            <input
                              type="radio"
                              name="furnishing"
                              value="lightly"
                              defaultChecked
                            />{" "}
                            Lightly furnished
                          </label>
                          <label className="radio-label">
                            <input
                              type="radio"
                              name="furnishing"
                              value="moderately"
                            />{" "}
                            Moderately furnished
                          </label>
                          <label className="radio-label">
                            <input
                              type="radio"
                              name="furnishing"
                              value="heavily"
                            />{" "}
                            Heavily furnished
                          </label>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div style={{ textAlign: "center" }}>
                      <button
                        className="btn next_link text-white"
                        onClick={handleViewInventory}
                        disabled={buttonDisabled}
                      >
                        Continue
                      </button>
                    </div>
                  )}

                  {estimateMethod === "roomByRoom" && (
                    <div style={{ textAlign: "center", paddingTop: "15px" }}>
                      <button
                        className="btn inventory__continue w-50"
                        onClick={handleViewInventory}
                      >
                        View Inventory
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )} */}

          {/* {visibleSection === "inventory-details" && (
            <div id="list-items" className="target-section">
              <div
                className="nav d-flex justify-content-around"
                role="tablist"
                aria-orientation="vertical"
              >
                <a className="nav-link ">Location</a>
                <a className="nav-link active">Move Size</a>
                <a className="nav-link">Date & Time</a>
                <a className="nav-link">Details</a>
                <a className="nav-link">My Quote</a>
              </div>
              <h3>Create an inventory to estimate your move size</h3>
              <p>
                <a
                  href="#"
                  onClick={handleReset}
                  style={{ textDecoration: "none" }}
                >
                  Change estimate method
                </a>
              </p>
              <ViewInventory />
            </div>
          )} */}
        </div>
      </div>
      {/* <div className="bg__footerContainer">
        <div className="footer-container">
          <button type="button" className="next_link" onClick={handlenextClick}>
            NEXT
          </button>
        </div>
      </div> */}
    </div>
  );
}

export default MovingLocation;
