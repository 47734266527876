import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import "../Footer/Footer.css";
import logo from "../Assests/cargo_truck.png";
import { Link } from "react-router-dom";
import logos from "../Assests/Skilled-Movers-Logo.png";

const CustomFooter = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="footer-logo">
              <img src={logos} alt="Logo" width="50%" />
              <p className="description pt-4" style={{ textAlign: "justify" }}>
                Moving homes? Our skilled movers handle your precious belongings
                with the utmost care, just as if they were our own. For peace of
                mind and expert service, look no further.
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="footer-links">
              <h3 className="text-dark">Useful Links</h3>
              <div className="divider"></div>
              <ul>
                <li>
                  <Link to="/aboutus">About Us</Link>
                </li>
                <li>
                  <Link
                    to="https://blogs.skilledmovers.com.au/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Blogs
                  </Link>
                </li>

                <li>
                  <Link to="/contactus">Contact Us</Link>
                </li>
                <li>
                  <Link to="/priviacyandpolicy">Privacy and policy</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="footer-contact">
              <h3 className="text-dark">Contact Details</h3>
              <div className="divider"></div>
              <div className="contact-info">
                <p style={{ color: "#7e7e7e" }}>
                  <FontAwesomeIcon
                    icon={faEnvelope}
                    className="me-2"
                    style={{ color: "#ff5c00" }}
                  />
                  <a
                    href="mailto:Info@skilledmovers.com.au"
                    className="text-decoration-none conatctmail"
                    style={{ color: "#7e7e7e" }}
                  >
                    Info@skilledmovers.com.au
                  </a>
                </p>
                <p style={{ color: "#7e7e7e" }}>
                  <FontAwesomeIcon
                    icon={faPhone}
                    className="me-2"
                    style={{ color: "#ff5c00" }}
                  />
                  <a
                    href="tel: +03 8907 0486"
                    className="text-decoration-none conatctmail"
                    style={{ color: "#7e7e7e" }}
                  >
                    +03 8907 0486
                  </a>{" "}
                  /{" "}
                  <a
                    href="tel: +0450 383 690"
                    className="text-decoration-none conatctmail"
                    style={{ color: "#7e7e7e" }}
                  >
                    +0450 383 690
                  </a>
                </p>
                {/* <div className="social-icons">
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebook} size="2x" />
                </a>
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
              </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 text-center">
            <p className="copyright text-dark">
              Copyright © 2025, All Right Reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default CustomFooter;
