import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../Home/HomePage.css";
import LocalMoves from "../../Assests/local_moves.png";
import country from "../../Assests/country.png";
import interstate from "../../Assests/street.png";
import office from "../../Assests/office.png";
import manager from "../../Assests/manager.png";
import wallclock from "../../Assests/wall-clock.png";
import trash from "../../Assests/trash.png";
import unpacking from "../../Assests/unpacking.png";
import mobile from "../../Assests/mobile.png";
import { Link } from "react-router-dom";
import mans from "../../Assests/medium-shot-man-holding-boxs.webp";

const ServiceCard = ({ icon, title, description }) => (
  <div className="custom-service-card">
    <div className="custom-icon mb-3">
      <img src={icon} alt={title} className="img-fluid" />
    </div>
    <h5 className="custom-title mb-3">{title}</h5>
    <p className="custom-description">{description}</p>
  </div>
);

export default function HomePage() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      <div className="container my-5">
        <h2 className="text-center ">Settle Into Your New Home with Ease</h2>
        <div className="text-center">
          <p>
            With over 50,000 moves completed, we have the expertise to make your
            relocation seamless.
          </p>
          <p>
            Skilled Movers simplifies the process, helping you find and book
            everything you need for an easy move.
          </p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <ServiceCard
              icon={office}
              title="Office Moves"
              description="We understand how critical a smooth move is for your business, and we strive to minimize downtime with our efficient service."
            />
          </div>
          {/* <div className="col-md-4">
            <ServiceCard
              icon={manager}
              title="Labour Only & Special Projects"
              description="Unique move? Let us customize a package for you. We're here to help."
            />
          </div> */}
          <div className="col-md-6">
            <ServiceCard
              icon={wallclock}
              title="Last Minute Moves"
              description="Need an urgent move? Call +03 8907 0486 now for fast, reliable, affordable, and budget-friendly moving services."
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <ServiceCard
              icon={LocalMoves}
              title="Local Moves"
              description="Choose expert, hand-picked removalists for any move, from a small apartment to a large home, at your convenience."
            />
          </div>
          <div className="col-md-6">
            <ServiceCard
              icon={unpacking}
              title="Packing & Unpacking"
              description="Relax with our professional packers. We provide all materials (boxes, bubble wrap, etc.). Contact us today."
            />
          </div>
          {/* <div className="col-md-4">
            <ServiceCard
              icon={country}
              title="Country Moves"
              description="Moving to or from rural areas? We serve within a four-hour radius of Sydney & Melbourne. Contact us today."
            />
          </div> */}
        </div>
        {/* <div className="row">
          <div className="col-md-4">
            <ServiceCard
              icon={trash}
              title="Rubbish Removal"
              description="Got excess rubbish? Start by visiting your nearest tip. We’ll add the tip fees to your job—simple and easy!"
            />
          </div>

          <div className="col-md-4">
            <ServiceCard
              icon={mobile}
              title="Utility Connections"
              description="Make your move simple and save on gas and electricity with our free utility connection."
            />
          </div>
          <div className="col-md-4">
            <ServiceCard
              icon={interstate}
              title="Interstate Moves"
              description="From arrival times to storage, we handle it all with transparent pricing and dedicated coordinators."
            />
          </div>
        </div> */}
        <div className="d-flex align-items-center justify-content-center">
          <Link to="/" className="Service_pricing" onClick={scrollToTop}>
            Get Instant Pricing
          </Link>
          <a href="tel:+03 8907 0486 " className="Service_call">
            +03 8907 0486
          </a>
        </div>
        <div className="my-5">
          <h2 className="text-center">
            Your Trusted Moving Company in Melbourne
          </h2>
          <div>
            <p className="information__justify">
              Welcome to <b>Skilled Movers</b>, your go-to{" "}
              <b>moving company in Melbourne</b> for hassle-free and efficient
              relocations! Whether you’re planning a residential move, an office
              relocation, or need help with furniture moving, our team of
              professional <b>Melbourne removalists</b> is here to make your
              moving experience smooth and stress-free.
            </p>
          </div>
        </div>

        <div className="row d-flex justify-content-center align-items-center my-5">
          <div className="col-md-12 col-sm-12 col-lg-6 skilled__movers__images">
            <img src={mans} alt="movers" className="img-fluid mover_image" />
          </div>
          <div className="col-md-12 col-sm-12 col-lg-6">
            <h2 className="text-center pt-3 p-lg-0">
              Discover the Skilled Movers Difference
            </h2>
            <p className="information__justify">
              At Skilled Movers, we combine quality, care, and trust to redefine
              the moving experience. As Australia’s largest network of
              professional removalists, we provide moving services designed to
              make relocations simple and stress-free.
            </p>
            <p className="information__justify">
              Our team of verified removalists is supported by advanced
              technology and a team of moving experts, ensuring a seamless and
              hassle-free relocation experience. When you’re moving , we take
              care of every detail with precision and reliability
            </p>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <h2 className="text-center">Our Services</h2>
        <div>
          <h3>Residential Moving</h3>
          <div>
            <p className="information__justify">
              Moving to a new home? Our team of expert movers in Melbourne
              specializes in packing, loading, transporting, and unpacking all
              your belongings with utmost care. From fragile items to bulky
              furniture, we ensure everything reaches your new address safely
              and on time.
            </p>
          </div>
        </div>
        <div>
          <h3>Office Moving</h3>
          <div>
            <p className="information__justify">
              Relocating your business? At Skilled Movers, we understand the
              importance of a swift and efficient office move. Our Melbourne
              removalists handle everything, from dismantling office furniture
              to securely transporting electronics and documents, minimizing
              downtime for your business.
            </p>
          </div>
        </div>
        <div>
          <h3>Furniture Moving</h3>
          <div>
            <p className="information__justify">
              Need to move heavy or valuable furniture? As a trusted removal
              company in Melbourne, we have the tools, skills, and experience to
              handle furniture of all shapes and sizes. From delicate antiques
              to large couches, we guarantee damage-free transportation.
            </p>
          </div>
        </div>
      </div>

      {/* ================================== */}
      <div className="container my-5">
        <h2 className="text-center mb-5">
          Melbourne's Most Trusted Furniture Removal Company
        </h2>

        <div className="row justify-content-center">
          <div className="col-md-3">
            <ul className="">
              <li>
                <Link
                  to="/narrewarren"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Narre Warren
                </Link>
              </li>
              <li>
                <Link
                  to="/berwick"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Berwick
                </Link>
              </li>
              <li>
                <Link
                  to="/pakenham"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Pakenham
                </Link>
              </li>
              <li>
                <Link
                  to="/cranbourne"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Cranbourne
                </Link>
              </li>
              <li>
                <Link
                  to="/hawthorn"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Hawthorn
                </Link>
              </li>
              <li>
                <Link
                  to="/keysborough"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Keysborough
                </Link>
              </li>
              <li>
                <Link
                  to="/templestowe"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Templestowe
                </Link>
              </li>
              <li>
                <Link
                  to="/boxhill"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Box Hill
                </Link>
              </li>
              <li>
                <Link
                  to="/croydon"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Croydon
                </Link>
              </li>
              <li>
                <Link
                  to="/balwyn "
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Balwyn
                </Link>
              </li>
              <li>
                <Link
                  to="/doncaster"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Doncaster
                </Link>
              </li>
            </ul>
          </div>

          <div className="col-md-3">
            <ul className="">
              <li>
                <Link
                  to="/oakleigh"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Oakleigh
                </Link>
              </li>
              <li>
                <Link
                  to="/clayton"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Clayton
                </Link>
              </li>
              <li>
                <Link
                  to="/boronia"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Boronia
                </Link>
              </li>
              <li>
                <Link
                  to="/glenwaverley"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Glen Waverley
                </Link>
              </li>
              <li>
                <Link
                  to="/mountwaverley"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Mount Waverley
                </Link>
              </li>
              <li>
                <Link
                  to="/frankston"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Frankston
                </Link>
              </li>
              <li>
                <Link
                  to="/caulfield"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Caulfield
                </Link>
              </li>
              <li>
                <Link
                  to="/mornington"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Mornington
                </Link>
              </li>
              <li>
                <Link
                  to="/seaford"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Seaford
                </Link>
              </li>
              <li>
                <Link
                  to="/essendon"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Essendon
                </Link>
              </li>
              <li>
                <Link
                  to="/frankstonsouth"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Frankston South
                </Link>
              </li>
            </ul>
          </div>

          <div className="col-md-3">
            <ul className="">
              <li>
                <Link
                  to="/armadale"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Armadale
                </Link>
              </li>
              <li>
                <Link
                  to="/burwood"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Burwood
                </Link>
              </li>
              <li>
                <Link
                  to="/southmelbourne"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  South Melbourne
                </Link>
              </li>
              <li>
                <Link
                  to="/bentleigh"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Bentleigh
                </Link>
              </li>
              <li>
                <Link
                  to="/easternsuburbs"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Eastern Suburbs
                </Link>
              </li>
              <li>
                <Link
                  to="/mordialloc"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Mordialloc
                </Link>
              </li>
              <li>
                <Link
                  to="/ringwood"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Ringwood
                </Link>
              </li>
              <li>
                <Link
                  to="/camberwell"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Camberwell
                </Link>
              </li>
              <li>
                <Link
                  to="/bayside"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Bayside
                </Link>
              </li>
              <li>
                <Link
                  to="/brighton"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Brighton
                </Link>
              </li>
              <li>
                <Link
                  to="/rowville"
                  className="citylist text-decoration-none"
                  style={{ color: "rgb(126, 126, 126)" }}
                >
                  Rowville
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="my-4">
          <h2 className="text-center">Why Choose Skilled Movers?</h2>
          <ul className="space-y-4">
            <li className="flex gap-2 mb-2">
              <span className="font-weight-bold">
                Packing & Unpacking Services:
              </span>
              Leave the packing to us! Our experts use high-quality materials to
              pack your belongings securely and unpack them at your new
              location.
            </li>
            <li className="flex gap-2 mb-2">
              <span className="font-weight-bold">Safe and Reliable:</span>
              Your items are in safe hands with Skilled Movers. Our experienced
              team takes extra precautions to ensure the safety of your
              belongings throughout the moving process.
            </li>
            <li className="flex gap-2 mb-2">
              <span className="font-weight-bold">Affordable Rates:</span>
              Moving doesn't have to break the bank. We offer competitive
              pricing for all our services, making us one of the most affordable
              movers in Melbourne.
            </li>
            <li className="flex gap-2 mb-2">
              <span className="font-weight-bold">
                Loading & Unloading Expertise:
              </span>
              Heavy lifting? No problem! Our trained professionals handle all
              loading and unloading tasks with efficiency and care.
            </li>
            <li className="flex gap-2">
              <span className="font-weight-bold">
                Customer-Centric Approach:
              </span>
              Your satisfaction is our priority. As a customer-focused moving
              company in Melbourne, we tailor our services to meet your specific
              needs.
            </li>
          </ul>
        </div>
        <div className="my-4">
          <h2 className="text-center">
            Experience Stress-Free Moves with Skilled Movers
          </h2>
          <div>
            <p className="information__justify">
              At Skilled Movers, we’re not just another removal company in
              Melbourne. We’re a team of dedicated professionals committed to
              providing top-notch moving services that you can trust. Whether
              you’re moving across the city or just down the street, our
              Melbourne removalists are here to help every step of the way.
            </p>
            <p className="information__justify">
              Ready to make your next move a breeze? Contact Skilled Movers
              today and discover why we’re the preferred choice for movers in
              Melbourne!
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <Link to="/" className="Service_pricing" onClick={scrollToTop}>
              Get Instant Pricing
            </Link>
            <a href="tel:+03 8907 0486 " className="Service_call">
              +03 8907 0486
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
