import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const fromLocation = localStorage.getItem("from_location");
  const toLocation = localStorage.getItem("to_location");

  if (!fromLocation || !toLocation) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
