import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import MovingForm from "./Components/Pages/Home/Home";
import CustomFooter from "./Components/Footer/Footer";
import NextPage from "./Components/Pages/Nextpage/Nextpage";
import NextpageFooter from "./Components/NextpageFooter/Nextpagefooter";
import VerticalTimeline from "./Components/Pages/Timeline/Timeline";
import AccessProperties from "./Components/Pages/TimelineLocation/Location";
import MovingLocation from "./Components/Pages/MovingLocation/MovingLocation";
import Services from "./Components/Pages/Services/Services";
import ContactUs from "./Components/Pages/ContactUs/Contactus";
import Faqs from "./Components/Pages/Faqs/Faqs";
import BecomeMover from "./Components/Pages/Become_Mover/BecomeMover";
import ParentComponent from "./Components/Parent/ParentComponent";
import MoveForm from "./Components/Pages/Date&Time/Date&Time";
import Size from "./Components/Pages/SizeEstimate/Size";
import Searchitem from "./Components/Pages/Searchitem/Searchitem";
import Details from "./Components/Pages/Details/Details";
import Myquote from "./Components/Pages/Myquote/Myquote";
import RoomInventory from "./Components/Pages/ViewInventory/Viewinventory";
import Completed from "./Components/Pages/Profile/Completed";
import Prfilescreen from "./Components/Pages/Profile/Profilescreen";
import Schedule from "./Components/Pages/Profile/Schedule";
import Cancelled from "./Components/Pages/Profile/Cancelled";
import InventoryMyquote from "./Components/Pages/Myquote/InventoryMyquote";
import QuickMyquote from "./Components/Pages/Myquote/QuickMyquote";
import HandlfullMyquote from "./Components/Pages/Myquote/HandfullMyquote";
import DetailCancelled from "./Components/Pages/Profile/DetailCancelled";
import DetailSchedule from "./Components/Pages/Profile/DetailSchedule";
import DetailCompleted from "./Components/Pages/Profile/DetailCompleted";
import HandleCompleted from "./Components/Pages/Profile/HandleCompleted";
import HandleCancelled from "./Components/Pages/Profile/HandleCancelled";
import HandleSchedule from "./Components/Pages/Profile/HandleSchedule";
import QuickCompleted from "./Components/Pages/Profile/QuickCompleted";
import QuickCancelled from "./Components/Pages/Profile/QuickCancelled";
import QuickSchedule from "./Components/Pages/Profile/QuickSchedule";
import QuoteDetail from "./Components/Pages/Profile/QuoteDetail";
import QuateQuick from "./Components/Pages/Profile/QuateQuick";
import HandleQuick from "./Components/Pages/Profile/HandleQuick";
import PaymentSuccess from "./Components/Pages/Payment/PaymentSuccess";
import PaymentFailed from "./Components/Pages/Payment/PaymentFailed ";
import Localremovalists from "./Components/Pages/Localremovalists/Localremovalists";
import Aboutus from "./Components/Pages/Aboutus/Aboutus";
import Privacyandpolicy from "./Components/Pages/Privacy&Policy/Privacyandpolicy";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TagManager from "react-gtm-module";
import { useEffect } from "react";
import Templestowe from "./Components/Pages/Templestowe/Templestowe";
import Seaford from "./Components/Pages/Seaford/Seaford";
import Ringwood from "./Components/Pages/RingWood/Ringwood";
import Oakleigh from "./Components/Pages/Oakleigh/Oakleigh";
import Mountwaverley from "./Components/Pages/Mount Waverley/Mountwaverley";
import Mornington from "./Components/Pages/Mornington/Mornington";
import Mordialloc from "./Components/Pages/Mordialloc/Mordialloc";
import Hawthorn from "./Components/Pages/Hawthorn/Hawthorn";
import Easternsuburbs from "./Components/Pages/EasternSuburbs/Easternsuburbs";
import Armadale from "./Components/Pages/Armadale/Armadale";
import Bayside from "./Components/Pages/Bayside/Bayside";
import Bentleigh from "./Components/Pages/Bentleigh/Bentleigh";
import Boronia from "./Components/Pages/Boronia/Boronia";
import BoxHill from "./Components/Pages/BoxHill/Boxhill";
import Brighton from "./Components/Pages/Brighton/Brighton";
import Burwood from "./Components/Pages/Burwood/Burwood";
import Camberwell from "./Components/Pages/Camberwell/Camberwell";
import Caulfield from "./Components/Pages/Caulfield/Caulfield";
import Clayton from "./Components/Pages/Clayton/Clayton";
import Cranbourne from "./Components/Pages/Cranbourne/Cranbourne";
import Croydon from "./Components/Pages/Croydon/Croydon";
import Doncaster from "./Components/Pages/Doncaster/Doncaster";
import Essendon from "./Components/Pages/Essendon/Essendon";
import Frankston from "./Components/Pages/FrankstonSouth/FrankstonSouth";
import FrankstonSouth from "./Components/Pages/Frankston/Frankston";
import GlenWaverley from "./Components/Pages/GlenWaverley/GlenWaverley";
import Keysborough from "./Components/Pages/Keysborough/Keysboroug";
import NarreWarren from "./Components/Pages/NarreWarren/NarreWarren";
import Pakenham from "./Components/Pages/Pakenham/Pakenham";
import Rowville from "./Components/Pages/Rowville/Rowville";
import SouthMelbourne from "./Components/Pages/SouthMelbourne/SouthMelbourne";
import Balwyn from "./Components/Pages/Balwyn/Balwyn";
import Berwick from "./Components/Pages/Berwick/Berwick";
import Thankyou from "./Components/Pages/Thankyou/Thankyou";
import Quoteinventory from "./Components/Pages/Emailqoutefile/Quoteinventory";
import Quotequick from "./Components/Pages/Emailqoutefile/Quotequick";
import Quotehandle from "./Components/Pages/Emailqoutefile/Quotehandle";
import Emailinventory from "./Components/Pages/Emailinventory/Emailinventory";
import Emailquickinventory from "./Components/Pages/Emailinventory/Emailquickinventory";
import Emailhandleinventory from "./Components/Pages/Emailinventory/Emailhandleinventory";
import ProtectedRoute from "./Components/ProtectedRoute/ProtectedRoute";
import PageNotFound from "./Components/Pages/PageNotFound/PageNotFound";

function App() {
  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-NKRDHCP2" });
  }, []);
  return (
    <div className="app">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<MovingForm />} />
          <Route path="/melbourne-removalists" element={<Localremovalists />} />
          <Route path="/templestowe" element={<Templestowe />} />
          <Route path="/armadale" element={<Armadale />} />
          <Route path="/ringwood" element={<Ringwood />} />
          <Route path="/thank-you" element={<Thankyou />} />
          <Route path="/easternsuburbs" element={<Easternsuburbs />} />
          <Route path="/boronia" element={<Boronia />} />
          <Route path="/balwyn" element={<Balwyn />} />
          <Route path="/berwick" element={<Berwick />} />
          <Route path="/boxhill" element={<BoxHill />} />
          <Route path="/brighton" element={<Brighton />} />
          <Route path="/burwood" element={<Burwood />} />
          <Route path="/camberwell" element={<Camberwell />} />
          <Route path="/caulfield" element={<Caulfield />} />
          <Route path="/clayton" element={<Clayton />} />
          <Route path="/cranbourne" element={<Cranbourne />} />
          <Route path="/croydon" element={<Croydon />} />
          <Route path="/doncaster" element={<Doncaster />} />
          <Route path="/essendon" element={<Essendon />} />
          <Route path="/frankston" element={<Frankston />} />
          <Route path="/frankstonsouth" element={<FrankstonSouth />} />
          <Route path="/glenwaverley" element={<GlenWaverley />} />
          <Route path="/keysborough" element={<Keysborough />} />
          <Route path="/narrewarren" element={<NarreWarren />} />
          <Route path="/pakenham" element={<Pakenham />} />
          <Route path="/rowville" element={<Rowville />} />
          <Route path="/southmelbourne" element={<SouthMelbourne />} />
          <Route path="/bentleigh" element={<Bentleigh />} />
          <Route path="/bayside" element={<Bayside />} />
          <Route path="/hawthorn" element={<Hawthorn />} />
          <Route path="/mordialloc" element={<Mordialloc />} />
          <Route path="/mountwaverley" element={<Mountwaverley />} />
          <Route path="/mornington" element={<Mornington />} />
          <Route path="/seaford" element={<Seaford />} />
          <Route path="/oakleigh" element={<Oakleigh />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/priviacyandpolicy" element={<Privacyandpolicy />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/becomeamover" element={<BecomeMover />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/accessproperties" element={<AccessProperties />} />
          </Route>{" "}
          <Route element={<ProtectedRoute />}>
            <Route path="/movinglocation" element={<MovingLocation />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/size" element={<Size />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/searchitem" element={<Searchitem />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/movefrom" element={<MoveForm />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/details" element={<Details />} />
          </Route>
          <Route path="/myquote" element={<Myquote />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/roominventory" element={<RoomInventory />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/handlfullmyquote" element={<HandlfullMyquote />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/inventorymyquote" element={<InventoryMyquote />} />
          </Route>
          <Route element={<ProtectedRoute />}>
            <Route path="/quickmyquote" element={<QuickMyquote />} />
          </Route>
          <Route path="/completed" element={<Completed />} />
          <Route path="/profile" element={<Prfilescreen />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/cancelled" element={<Cancelled />} />
          <Route path="/detailcancelled" element={<DetailCancelled />} />
          <Route path="/detailschedule" element={<DetailSchedule />} />
          <Route path="/detailcompleted" element={<DetailCompleted />} />
          <Route path="/handlecompleted" element={<HandleCompleted />} />
          <Route path="/handlecancelled" element={<HandleCancelled />} />
          <Route path="/handleschedule" element={<HandleSchedule />} />
          <Route path="/quickcompleted" element={<QuickCompleted />} />
          <Route path="/quickcancelled" element={<QuickCancelled />} />
          <Route path="/quickschedule" element={<QuickSchedule />} />
          <Route path="/quotedetail" element={<QuoteDetail />} />
          <Route path="/quatequick" element={<QuateQuick />} />
          <Route path="/handlequick" element={<HandleQuick />} />
          <Route path="/PaymentSuccess" element={<PaymentSuccess />} />
          <Route path="/PaymentFailed" element={<PaymentFailed />} />
          <Route
            path="/quoteinventory/:enquiryId"
            element={<Quoteinventory />}
          />
          <Route path="/quotequick/:enquiryId" element={<Quotequick />} />
          <Route path="/quotehandfull/:enquiryId" element={<Quotehandle />} />
          <Route path="/emailinventory" element={<Emailinventory />} />
          <Route
            path="/emailquickinventory"
            element={<Emailquickinventory />}
          />
          <Route
            path="/emailhandfullinventory"
            element={<Emailhandleinventory />}
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
        <ToastContainer />
        <CustomFooter />
      </BrowserRouter>
    </div>
  );
}

export default App;
